<template>
    <div :class="containerClass" @click="onDocumentClick($event)">
            <AppTopBar :topbarTheme="topbarTheme" :activeTopbarItem="activeTopbarItem"
                @menu-button-click="onMenuButtonClick($event)" @right-panel-click="onRightPanelButtonClick" @topbar-item-click="onTopbarItemClick" @search-click="onSearchClick"
            ></AppTopBar>

        <AppRightPanel @right-panel-click="onRightPanelClick" ></AppRightPanel>

        <div class="menu-wrapper" @click="onMenuClick($event)">
            <AppMenu :model="menu" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" :menuMode="menuMode"
                @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
        </div>

        <div class="layout-main">
            <AppBreadcrumb></AppBreadcrumb>

            <div class="layout-content">
                <router-view/>
            </div>

            <AppFooter></AppFooter>
        </div>
            

        <AppConfig v-model:theme="theme" v-model:layoutMode="layoutMode" v-model:menuMode="menuMode" v-model:menuTheme="menuTheme" v-model:topbarTheme="topbarTheme" :configActive="configActive" :configClick="configClick" @config-click="onConfigClick" @config-button-click="onConfigButtonClick"></AppConfig>

        <div v-if="staticMenuMobileActive" class="layout-mask modal-in"></div>
    </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from "./AppTopbar";
import AppMenu from "./AppMenu";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import AppRightPanel from "./AppRightPanel";
import AppBreadcrumb from "./AppBreadcrumb";
export default {
    data() {
        return {
            theme: 'denim',
            topbarTheme: "light",
            menuTheme: "dim",
            layoutMode: "light",
            menuMode: "static",
            activeTopbarItem: null,
            menuActive: false,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarMenuActive: false,
            rightPanelClick: false,
            rightPanelActive: false,
            configActive: false,
            configClick: false,
            menu: [
                {
                    label: "Favorites", icon: "pi pi-fw pi-home",
                    items: [
                        {label: "Dashboard", icon: "pi pi-fw pi-home", to: "/"},
                    ],
                },
                {
                    label: "UI Kit", icon: "pi pi-fw pi-star-fill",
                    items: [
                        {label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout"},
                        {label: "Input", icon: "pi pi-fw pi-check-square", to: "/input"},
                        {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
                        {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate"},
                        {label: "Button", icon: "pi pi-fw pi-mobile", to: "/button", class: 'rotated-icon'},
                        {label: "Table", icon: "pi pi-fw pi-table", to: "/table"},
                        {label: "List", icon: "pi pi-fw pi-list", to: "/list"},
                        {label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree"},
                        {label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel"},
                        {label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay"},
                        {label: "Media", icon: "pi pi-fw pi-image", to: "/media"},
                        {label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu"},
                        {label: "Message", icon: "pi pi-fw pi-comment", to: "/messages"},
                        {label: "File", icon: "pi pi-fw pi-file", to: "/file"},
                        {label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart"},
                        {label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc"},
                    ],
                },
                {
                    label: "Prime Blocks", icon: "pi pi-fw pi-prime",
                    items: [
                        {label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks"},
                        {label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-vue", target: "_blank"}
                    ]
                },
                {
                    label: "Utilities", icon: "pi pi-fw pi-compass",
                    items: [
                        {label: "PrimeIcons", icon:"pi pi-fw pi-prime", to:"/icons"},
                        {label: 'PrimeFlex', icon:'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank'}
                    ],
                },
                {
                    label: "Pages", icon: "pi pi-fw pi-briefcase",
                    items: [
                        {label: "Crud", icon: "pi pi-fw pi-pencil", to: "/crud"},
                        {label: "Calendar", icon: "pi pi-fw pi-calendar-plus", to: "/calendar"},
                        {label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
                        {label: "Landing", icon: "pi pi-fw pi-user-plus", url: "pages/landing.html", target: "_blank"},
                        {label: "Login", icon: "pi pi-fw pi-sign-in", to: "/login"},
                        {label: "Invoice", icon: "pi pi-fw pi-dollar", to: "/invoice"},
                        {label: "Help", icon: "pi pi-fw pi-question-circle", to: "/help"},
                        {label: "Error", icon: "pi pi-fw pi-times-circle", to: "/error"},
                        {label: "Not Found", icon: "pi pi-fw pi-exclamation-circle", to: "/notfound"},
                        {label: "Access Denied", icon: "pi pi-fw pi-lock", to: "/access"},
                        {label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty"}
                    ],
                },
                {
                    label: "Hierarchy", icon: "pi pi-fw pi-align-left",
                    items: [
                        {
                            label: "Submenu 1",icon: "pi pi-fw pi-align-left",
                            items: [
                                {
                                    label: "Submenu 1.1", icon: "pi pi-fw pi-align-left",
                                    items: [
                                        {label: "Submenu 1.1.1", icon: "pi pi-fw pi-align-left"},
                                        {label: "Submenu 1.1.2", icon: "pi pi-fw pi-align-left"},
                                        {label: "Submenu 1.1.3", icon: "pi pi-fw pi-align-left"},
                                    ],
                                },
                                {
                                    label: "Submenu 1.2", icon: "pi pi-fw pi-align-left",
                                    items: [
                                        {label: "Submenu 1.2.1", icon: "pi pi-fw pi-align-left"},
                                    ],
                                },
                            ],
                        },
                        {
                            label: "Submenu 2", icon: "pi pi-fw pi-align-left",
                            items: [
                                {
                                    label: "Submenu 2.1", icon: "pi pi-fw pi-align-left",
                                    items: [
                                        {label: "Submenu 2.1.1", icon: "pi pi-fw pi-align-left"},
                                        {label: "Submenu 2.1.2", icon: "pi pi-fw pi-align-left"},
                                    ],
                                },
                                {
                                    label: "Submenu 2.2", icon: "pi pi-fw pi-align-left",
                                    items: [
                                        {label: "Submenu 2.2.1", icon: "pi pi-fw pi-align-left"},
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "Start", icon: "pi pi-fw pi-download",
                    items: [
                        {label: "Buy Now", icon: "pi pi-fw pi-shopping-cart", command: () => window.open("https://www.primefaces.org/store", "_blank")},
                        {label: "Documentation", icon: "pi pi-fw pi-info-circle", to: "/documentation"},
                    ],
                },
            ],
        };
    },
    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-overlay": this.menuMode === "overlay",
                    "layout-static": this.menuMode === "static",
                    'layout-horizontal': this.menuMode === 'horizontal',
                    "layout-overlay-active": this.overlayMenuActive,
                    "layout-mobile-active": this.staticMenuMobileActive,
                    "layout-static-active": !this.staticMenuDesktopInactive && this.menuMode === "static",
                    "layout-rightpanel-active": this.rightPanelActive,
                    "layout-rtl": this.$appState.isRTL,
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !this.$primevue.config.ripple,
                },
                'layout-menu-'+ this.menuTheme+ ' layout-topbar-'+ this.topbarTheme
            ];
        },
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        onDocumentClick() {
            if (!this.searchClick) {
                this.searchActive = false;
            }

            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.userMenuClick) {
                this.topbarMenuActive = false;
            }

            if (!this.rightPanelClick) {
                this.rightPanelActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal()) {
					EventBus.emit('reset-active-index');
					this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.topbarItemClick = false;
            this.rightPanelClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.rotateMenuButton = !this.rotateMenuButton;
            this.topbarMenuActive = false;
            this.menuClick = true;

            if (this.isOverlay() && !this.isMobile()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isHorizontal()) {
                this.menuActive = false;
            }
        },
		onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onRightPanelClick() {
            this.rightPanelClick = true;
        },
        onRightPanelButtonClick(event) {
            this.rightPanelClick = true;
            this.rightPanelActive = !this.rightPanelActive;
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) {
                this.activeTopbarItem = null;
            }
            else {
                this.activeTopbarItem = event.item;
            }

            if (event.item === 'search') {
                this.searchActive = !this.searchActive;
                this.searchClick = !this.searchClick;
            }

            event.originalEvent.preventDefault();
        },
        onSearchClick(event) {
            this.searchClick = true;
            event.stopPropagation();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isTablet() {
            const width = window.innerWidth;
            return width <= 1024 && width > 640;
        },
        isDesktop() {
            return window.innerWidth > 992;
        },
        isMobile() {
            return window.innerWidth <= 640;
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        }
    },
    components: {
        AppTopBar,
        AppMenu,
        AppFooter,
        AppConfig,
        AppRightPanel,
        AppBreadcrumb,
    }
};
</script>

<style lang="scss">
@import "App.scss";
</style>
